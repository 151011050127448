<template>
  <fragment>
    <h2 class="offer-title">{{ $t('components.find_card_step_form.found_multipublisher_offer_card_modal.text') }}</h2>

    <div class="offer-details">
      <div class="offer-balance-infos">
        <img class="offer-image-logo" :src="horizontalLogoImageHelper(instantCardOffer.partner_image[currentLocale])" alt="" />
        <offer-balance-card :image-src="instantCardOffer.images[currentLocale][0]" :balance="instantCardOffer.payment_amount + instantCardOffer.bonus_amount" :stay-small="true" />
      </div>
      <div class="offer-infos">
        <p class="offer-infos-label">{{ instantCardOffer.label[currentLocale] }}</p>
        <p class="offer-infos-description">{{ instantCardOffer.description[currentLocale] }}</p>
        <p>{{ successModalExpirationText }} {{ $t('components.find_card_step_form.found_card_modal.reassuring_text') }}</p>
      </div>
    </div>

    <h2 class="choice-header">{{ $t('components.find_card_step_form.found_multipublisher_offer_card_modal.multipublisher_selection.header_title') }}</h2>
    <v-radio-group v-if="offerPublisherOptions.length > 0" v-model="radioGroup" row>
      <div v-for="(publisher, i) in offerPublisherOptions" :key="i" @click="selectOfferPublisher(publisher)" class="choice" :class="{ 'selected-publisher': selectedOfferPublisherId && selectedOfferPublisherId === publisher.publisher_id }">
        <v-radio class="publisher-title-radio" v-if="publisher.status !== 'sold_out'" :key="publisher.publisher_id" :value="publisher.publisher_id">
          <template #label>
            <div class="publisher-title">
              <div class="publisher-title-text">{{ publisher.label }}</div>
              <div class="sold-out-tag h-heading-h4" v-if="publisher.status === 'sold_out'">{{ $t('components.find_card_step_form.found_multipublisher_offer_card_modal.multipublisher_selection.sold_out') }}</div>
            </div>
          </template>
        </v-radio>

        <div class="publisher-image" :style="{ backgroundImage: 'url(' + publisher.first_showcase_url + ')' }"></div>
        <p>{{ publisher.tagline }}</p>
        <merchants-modal
          :offer_id="instantCardOffer.id"
          :publisher_id="publisher.publisher_id"
          :modal-link-text="$t('components.find_card_step_form.found_multipublisher_offer_card_modal.multipublisher_selection.store_list_link_title')"
          :modal-title-text="$t('components.find_card_step_form.found_multipublisher_offer_card_modal.multipublisher_selection.store_list_modal_title')"
          :display-close-button="true"
        />
      </div>
    </v-radio-group>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import { horizontalLogoImageHelper } from '@/composables/image-helper';
import OfferBalanceCard from '@/components/instant-card-activation/OfferBalanceCard';
import MerchantsModal from '@/components/modals/MerchantsModal';
import safeExecute from '@/composables/safe-execute';
import _first from 'lodash/first';
import _map from 'lodash/map';
import _get from 'lodash/get';

export default {
  name: 'find-card-success-with-multipublisher-offer',
  components: { OfferBalanceCard, MerchantsModal },
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      horizontalLogoImageHelper,
      execute,
      working,
    };
  },
  data() {
    return {
      offerPublisherOptions: this.instantCardOfferPublishers || [],
      radioGroup: '',
    };
  },
  created() {
    this.getInstantCardOfferPublishers(this.instantCardOffer.id);
  },
  props: {
    selectedOfferPublisherId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('instantCard', ['instantCardOffer', 'instantCardOfferPublishers']),
    ...mapGetters('ui', ['currentLocale']),
    infoModalDetailText() {
      return this.infoModalExpandedDescription ? this.$t('components.find_card_step_form.found_card_modal.less_detail') : this.$t('components.find_card_step_form.found_card_modal.more_detail');
    },
    infoModalDetailIcon() {
      return this.infoModalExpandedDescription ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
    successModalExpirationText() {
      const passedArgs = {
        bonus_amount: this.$options.filters.currencySymbol(this.$options.filters.currency_full(this.instantCardOffer.bonus_amount)),
        payment_amount: this.$options.filters.currencySymbol(this.$options.filters.currency_full(this.instantCardOffer.payment_amount)),
        closure_date: this.$options.filters.formatTextDate(this.instantCardOffer.closure_date),
        bonus_expiration_date: this.$options.filters.formatTextDate(this.instantCardOffer.bonus_expiration_date),
      };

      if (this.instantCardOffer.payment_amount > 0 && this.instantCardOffer.bonus_amount > 0) {
        return this.$t('components.find_card_step_form.found_card_modal.expiration_mixed', passedArgs);
      } else if (this.instantCardOffer.payment_amount > 0 && this.instantCardOffer.closure_date) {
        //If it has closure date then is not finale sale
        if (this.instantCardOffer.closure_date) {
          return this.$t('components.find_card_step_form.found_card_modal.expiration_contribution', passedArgs);
        }

        return '';
      } else if (this.instantCardOffer.bonus_amount > 0) {
        return this.$t('components.find_card_step_form.found_card_modal.expiration_bonus', passedArgs);
      }
      return '';
    },
  },
  methods: {
    async getInstantCardOfferPublishers(offer_id) {
      return this.execute(
        {
          action: 'instantCard/getInstantCardOfferPublishers',
          success: (res) => {
            this.$emit('selectedOfferPublisherId', '');
            this.offerPublisherOptions = _map(res.items, (item) => {
              const locale = this.currentLocale;
              const { branding, ...props } = item;
              const { images, ...brandingProps } = branding;

              const first_showcase_url = _first(_get(images, `showcases.${locale}`));
              const label = _get(brandingProps, `label.${locale}`);
              const description = _get(brandingProps, `description.${locale}`);
              const tagline = _get(brandingProps, `tagline.${locale}`);

              return { ...props, first_showcase_url, label, description, tagline };
            });
          },
        },
        { offer_id, locale: this.currentLocale },
      );
    },
    selectOfferPublisher(publisher) {
      if (publisher.status === 'available') {
        if (this.selectedOfferPublisherId !== publisher.publisher_id) {
          this.radioGroup = publisher.publisher_id;
          this.$emit('selectedOfferPublisherId', publisher.publisher_id);
        } else {
          this.radioGroup = '';
          this.$emit('selectedOfferPublisherId', '');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-title {
  margin: var(--gap-xlarge) 0 var(--gap) 0;
}

.offer-details {
  padding: var(--gap) 0;

  @media ($md-min) {
    display: flex;
    gap: var(--gap-xxlarge);
    .offer-balance-infos {
      flex-basis: 45%;
    }
    .offer-infos {
      flex-basis: 55%;
    }
  }
}

.offer-image-logo {
  display: block;
  max-height: 60px;
  max-width: 150px;

  @media ($sm-min) {
    max-height: 80px;
    max-width: 200px;
  }
}

.offer-balance-card {
  max-width: none;
  margin: var(--gap) 0 var(--gap-xxlarge) 0;
  @media ($md-min) {
    margin: var(--gap) 0 0 0;
  }
}

.offer-infos {
  > * {
    margin-bottom: var(--gap-small);
  }
}

.offer-infos-label {
  font-size: var(--font-size-subheader);
  line-height: 1.25;
  font-weight: 600;
}

.offer-infos-description {
  color: var(--color-text-secondary);
}

.choice-header {
  text-align: center;
  border-bottom: var(--border-thick) solid var(--color-border-light);
  padding-bottom: var(--gap);
  margin: var(--gap-large) 0 var(--gap) 0;
}

::v-deep .v-input--radio-group__input {
  gap: var(--gap);
}

.choice {
  width: 100%;
  padding: calc(var(--gap) - 2px);
  border: 2px solid var(--color-border);
  border-radius: var(--border-radius);
  @media ($md-min) {
    width: calc((100% - var(--gap)) / 2);
  }

  &.selected-publisher {
    border: var(--border-thick) solid #adafff;
    box-shadow: var(--card-shadow);
    border-radius: var(--border-radius);
    padding: calc(var(--gap) - var(--border-thick));
  }

  > div {
    margin-bottom: var(--gap-xsmall);
  }
  ::v-deep .h-link--bold {
    font-size: var(--font-size-regular) !important;
  }
  :hover {
    cursor: pointer;
  }
}

.publisher-title {
  display: flex;
  gap: 6px;
  justify-content: space-between;
  align-items: center;
}

.publisher-title-radio {
  margin-right: 0 !important;
  margin-left: -2px;
}

.publisher-title-text {
  color: var(--color-text-primary);
  font-size: var(--font-size-subheader);
  line-height: 1.25;
  font-weight: 500;
}

.publisher-image {
  padding-top: 50%;
  border-radius: var(--border-radius);
  background-size: cover;
}

.sold-out-tag {
  color: var(--color-error);
  font-style: italic;
}
</style>
