<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" :title="$t('components.find_card_step_form.info_modal_ref_id.title')">
    <p>{{ $t('components.find_card_step_form.info_modal_ref_id.text') }}</p>
    <img class="card-img" src="@/assets/hello_card_back_ref_id.png" :alt="$t('components.find_card_step_form.info_modal_ref_id.image_alt_text')" />
  </h-dialog>
</template>

<script>
export default {
  name: 'find-card-ref-id-modal',
};
</script>

<style lang="scss" scoped>
.card-img {
  display: block;
  max-width: 100%;
}
</style>
