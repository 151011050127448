import { render, staticRenderFns } from "./InstantCardActivationView.vue?vue&type=template&id=19848fc6&scoped=true"
import script from "./InstantCardActivationView.vue?vue&type=script&lang=js"
export * from "./InstantCardActivationView.vue?vue&type=script&lang=js"
import style0 from "./InstantCardActivationView.vue?vue&type=style&index=0&id=19848fc6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19848fc6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
installComponents(component, {VIcon,VStepper,VStepperContent,VStepperItems})
