<template>
  <h-form class="find-card-step-form" ref="find-card" v-model="valid" @submit.prevent="findCard">
    <h-text-field
      v-maska="'###-###-###'"
      v-model="referenceId"
      :label="$t('components.find_card_step_form.ref_id_label')"
      :rules="refIdRules"
      type="text"
      required
      append-icon="mdi-help-circle-outline"
      @click:append="refIdModalIsOpen = true"
      maxlength="11"
      autocomplete="disable-autofill"
      :hint="$t('components.find_card_step_form.ref_id_hint')"
      persistent-hint
    />

    <find-card-ref-id-modal v-model="refIdModalIsOpen" />

    <h-text-field
      v-maska="{ mask: '!*!*!*!*-!*!*!*!*-!*!*!*!*-####' }"
      v-model="last4Digits"
      :label="$t('components.find_card_step_form.last_4_digits_label')"
      type="tel"
      pattern="[0-9][0-9][0-9][0-9]"
      :rules="last4DigitsRules"
      required
      append-icon="mdi-help-circle-outline"
      @click:append="fourDigitsModalIsOpen = true"
      maxlength="32"
      autocomplete="disable-autofill"
      :hint="$t('components.find_card_step_form.last_4_digits_hint')"
      persistent-hint
      class="mt-4"
    />

    <find-card-four-digits-modal v-model="fourDigitsModalIsOpen" />

    <find-card-success-modal v-model="foundCardModalIsOpen" @close="$emit('next')" />
    <find-multiple-offer-instant-card-success-modal v-model="foundMultipleOfferInstantCardModalIsOpen" @close="$emit('next')" />
    <find-card-success-modal-without-voucher v-model="foundCardModalWithoutVoucherIsOpen" @close="$emit('next')" />

    <find-card-error-modal v-model="errorModalIsOpen" :error-title="$t(`components.find_card_step_form.error_modal.${errorCode}.title`)">
      <template #text>
        <p v-if="errorCode === 'invalid_instant_card_information'">
          {{ $t(`components.find_card_step_form.error_modal.${errorCode}.text_1`) }}
          <router-link to="/my-account">{{ $t(`components.find_card_step_form.error_modal.${errorCode}.link`) }}</router-link>
          {{ $t(`components.find_card_step_form.error_modal.${errorCode}.text_2`) }}<br />
          <br />
          {{ $t(`components.find_card_step_form.error_modal.${errorCode}.text_3`) }}
        </p>
        <p v-else>
          {{ $t(`components.find_card_step_form.error_modal.${errorCode}.text`) }}
        </p>
      </template>
    </find-card-error-modal>
  </h-form>
</template>
<script>
import { validateLastFourDigits, validatePublicToken, validateRequired } from '@/composables/validations';
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';
import { recaptcha } from '@/mixins';
import FindCardRefIdModal from '@/components/instant-card-activation/FindCardRefIdModal';
import FindCardFourDigitsModal from '@/components/instant-card-activation/FindCardFourDigitsModal';
import FindCardSuccessModal from '@/components/instant-card-activation/FindCardSuccessModal';
import FindCardSuccessModalWithoutVoucher from '@/components/instant-card-activation/FindCardSuccessModalWithoutVoucher';
import FindMultipleOfferInstantCardSuccessModal from '@/components/instant-card-activation/FindMultipleOfferInstantCardSuccessModal';
import FindCardErrorModal from '@/components/instant-card-activation/FindCardErrorModal';
import _replace from 'lodash/replace';

export default {
  name: 'find-card-step-form',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);

    return {
      working,
      executeForm,
      validateRequired,
      validatePublicToken,
      validateLastFourDigits,
    };
  },
  components: { FindCardRefIdModal, FindCardFourDigitsModal, FindCardSuccessModal, FindCardErrorModal, FindCardSuccessModalWithoutVoucher, FindMultipleOfferInstantCardSuccessModal },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      refIdRules: [this.validatePublicToken(), this.validateRequired('card_ref_id')],
      last4DigitsRules: [this.validateRequired('last_4_digits'), this.validateLastFourDigits()],
      referenceId: '',
      last4Digits: '',
      foundCardModalIsOpen: false,
      foundMultipleOfferInstantCardModalIsOpen: false,
      foundCardModalWithoutVoucherIsOpen: false,

      refIdModalIsOpen: false,
      fourDigitsModalIsOpen: false,

      errorModalIsOpen: false,
      errorCode: 'invalid_instant_card_information',
    };
  },
  computed: {
    ...mapGetters('instantCard', ['hasInstantCardOffer', 'isSinglePublisher', 'isMultipleOffersInstantCard']),
    ...mapGetters('space', ['currentPartnerId']),
  },
  methods: {
    async findCard() {
      if (this.$refs['find-card'].validate()) {
        const params = {
          referenceId: _replace(this.referenceId, /-/g, ''),
          last4Digits: _replace(this.last4Digits, /[\*-]/g, ''),
          recaptcha_token: await this.recaptcha('findActivatableInstantCard'),
        };
        if (this.currentPartnerId) {
          params.partner_id = this.currentPartnerId;
        }

        this.executeForm(
          {
            action: 'instantCard/findActivatableInstantCard',
            afterError: (err) => {
              this.errorCode = err.response_code;
              this.errorModalIsOpen = true;
            },
            success: () => {
              if (!this.hasInstantCardOffer) {
                this.foundCardModalWithoutVoucherIsOpen = true;
              } else if (this.isMultipleOffersInstantCard) {
                this.foundMultipleOfferInstantCardModalIsOpen = true;
              } else if (this.isSinglePublisher) {
                this.foundCardModalIsOpen = true;
              } else {
                this.$emit('next');
              }
            },
          },
          params,
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input__append-inner {
  top: 32px !important;
}
</style>
